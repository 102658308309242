import React, { useEffect, useState } from 'react';
import './Questions.css';

import win1Image from '../assets/images/win-1.png';
import myketImage from '../assets/images/mykey.jpg';
import bazarImage from '../assets/images/bazaar.jpg';
import axios from 'axios';
import { useNavigate } from 'react-router';

const Questions = () => {
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobile, setMobile] = useState('');

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('https://golmirzaei.ir/game/api/game/first-step');
        setQuestions(response.data.questions);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };
    fetchQuestions();
  }, []);

  const handleNextQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];
    const correctAnswer = currentQuestion.answers.find(answer => answer.correct_status === "true");

    if (selectedAnswer === correctAnswer) {
      setTotalPoint(prevTotal => prevTotal + currentQuestion.question.point);
    }

    setSelectedAnswer(null);

    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handleRegister = async () => {
    setIsSubmitting(true);
    try {
      await axios.post('https://golmirzaei.ir/game/api/game/register', {
        mobile: mobile,
        point: totalPoint,
      });
      alert('شماره ثبت شد!');
    } catch (error) {
      alert(error.response.data.error)
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!questions.length) return <div>در حال بارگذاری سوالات...</div>;

  if (isQuizFinished) {
    return (
      <div className='h-0'>
        <div className='d-flex fs-35 pt-100 justify-content-center fw-700'>جمع امتیاز تو : {totalPoint}</div>

        {totalPoint > 55 && (
          <div className='d-flex fs-35 justify-content-center fw-700'>
            تبریک میگم برنده شدی، یه گل انتخاب کن!
          </div>
        )}

        {totalPoint > 65 && (
          <div className='d-flex fs-35 justify-content-center fw-700'>
            علاوه بر جایزه؛ تو قرعه کشی شرکت داده میشی
          </div>
        )}

        <div className='mt-50 justify-content-center'>
          <input
            type='phone'
            placeholder='شماره موبایل'
            className='input-mob'
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>

        <div className='mt-50 justify-content-center'>
          <div className='button-red fw-700 fs-60 w-50' onClick={handleRegister}>
            {isSubmitting ? 'در حال ارسال...' : 'ثبت شماره'}
          </div>
        </div>




        <div className='d-flex justify-content-center mt-50'>
          <div className=''>
            <img src={bazarImage} alt="Bazar" className='img-result ml-150' />
            <div className='justify-content-center fs-60 ml-150 download-text fw-700'>بازار</div>
          </div>

          <div className=''>
            <img src={myketImage} alt="Myket" className='img-result' />
            <div className='justify-content-center fs-60 download-text fw-700'>مایکت</div>
          </div>
        </div>


        <div className='justify-content-center'>
          <div className='button-green fw-700 fs-60 mt-130 w-50' onClick={() => navigate('/')}>
            شروع دوباره
          </div>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className='body-questions'>
      <div className='body-question-container'>
        <div className='navbar d-flex w-100 justify-content-space-between align-items mt-25'>
          <div>
            <div className='fs-40 color-green fw-700'>امتیاز شما: {totalPoint}</div>
            <div className='fs-35 fw-700'>امتیاز جایزه: 55</div>
          </div>
          <div className='d-flex'>
            <div>
              <img src={win1Image} alt="Logout" className='ml-30 img-intro' onClick={() => navigate('/')} />
            </div>
            <div>
              <div className='fs-35 fw-700'>اپلیکیشن گُلت</div>
              <div className='fs-35 color-green'>golet.app</div>
            </div>
          </div>
        </div>

        <div className='mt-50 justify-content-center'>
          {currentQuestion.question.image_url && (
            <img
              src={`https://golmirzaei.ir/game/${currentQuestion.question.image_url}`}
              alt="Question Image"
              className='ml-30 img-question'
            />
          )}
        </div>

        <div className='fs-35 fw-700 justify-content-center mt-35'>{currentQuestion.question.title}</div>

        <div className='answers-body'>
          {currentQuestion.answers.map((answer, index) => (
            <div key={index} className='answers-container mt-50 fs-35'>
              <div className='d-flex align-items-center mt-25'>
                <input
                  type="radio"
                  name="answers"
                  className='radio-btn'
                  checked={selectedAnswer === answer}
                  onChange={() => setSelectedAnswer(answer)}
                />
                <label>
                  {answer.title}
                  {answer.image_url && (
                    <img
                      src={`https://golmirzaei.ir/game/${answer.image_url}`}
                      className='ml-30 img-answers'
                      alt="Answer Image"
                    />
                  )}
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className='d-flex justify-content-center'>
          <div className='button-green fw-700 fs-40 mt-80 w-900' onClick={handleNextQuestion}>
            مطمئنم، بعدی
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
